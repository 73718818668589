<template>
  <div class="classManagement">
    <s-header />
    <n-bar :userType="'teacher'" :activeItemName="'classManagement'" />
    <support />

    <div class="pageContent">
      <div class="in p-auto">
        <div class="nothing" v-if="!inClassLis.length">
          <van-empty
            :image="require('@/assets/images/notClass.png')"
            image-size="100px"
            description="未关联班级，快去关联一个班级吧"
          />
          <!-- <div>
            <van-button
              plain
              hairline
              type="primary"
              block
              :icon="require('@/assets/images/addClass.png')"
              @click="showAddClass = true"
            >
              加入班级
            </van-button>
          </div> -->
        </div>

        <div>
          <van-list
            v-model:loading="caseLoading"
            :finished="caseLoadingFinished"
            finished-text="没有更多了"
            @load="getCaseList"
          >
            <List :list="inClassLis"></List>
          </van-list>
        </div>
      </div>
    </div>

    <van-popup v-model:show="showAddClass">
      <div class="addForm">
        <h2>加入班级</h2>
        <div class="form">
          <p>请选择您要加入的班级</p>
          <div class="gradeAndClass">
            <div class="grade">
              <span @click="showGrade = !showGrade">{{ gradeName }}</span>
              <div class="gradeSelect" v-show="showGrade">
                <ul>
                  <li
                    v-for="item in gradeList"
                    @click="clickGrade(item.id, item.gradeName)"
                    :key="item.id"
                  >
                    {{ item.gradeName }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="class">
              <span @click="showClass = !showClass">{{ className }}</span>
              <div class="classSelect" v-show="showClass">
                <ul>
                  <li
                    v-for="item in classList"
                    @click="clickClass(item.id, item.className)"
                    :key="item.id"
                  >
                    {{ item.className }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <p>请问是否为本班班主任</p>
          <van-radio-group v-model="ClassTeacher" direction="horizontal">
            <van-radio :name="1">我是班主任</van-radio>
            <van-radio :name="2">我是科任老师</van-radio>
          </van-radio-group>

          <p>请选择您任教的学科</p>
          <van-radio-group v-model="subjectTeacher" direction="horizontal">
            <van-radio :name="1">语文</van-radio>
            <van-radio :name="2">数学</van-radio>
          </van-radio-group>

          <div class="fnBtn">
            <van-button
              type="warning"
              size="mini"
              round
              @click="showAddClass = false"
              >返回</van-button
            >
            <van-button type="primary" size="mini" round>加入班级</van-button>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { ref } from "vue";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import List from "../components/ClassManaList.vue";
import { apiGetTeacherBindClass } from "@/service/teacher1.4.js";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

let inClassLis = ref([]);
let caseLoading = ref(true);
// 结束loading效果
let caseLoadingFinished = ref(false);
const getCaseList = async () => {
  let res = await apiGetTeacherBindClass({
    gradeId: userInfo.bindData.grade_id,
    academicYear: userInfo.now_academicYear,
  });
  if (res) {
    inClassLis.value = res.data;
  }
  caseLoading.value = false;
  caseLoadingFinished.value = true;
};

let showAddClass = ref(false);
let showGrade = ref(false);
let showClass = ref(false);

let ClassTeacher = ref(1);
let subjectTeacher = ref(1);

// 年级操作
let gradeName = ref("请选择年级");
let gradeId = ref("");
let gradeList = ref([
  { id: 1, gradeName: "高一" },
  { id: 2, gradeName: "高二" },
  { id: 3, gradeName: "高三" },
]);
const clickGrade = (id, name) => {
  gradeId.value = id;
  gradeName.value = name;
  showGrade.value = false;
};

// 班级操作
let className = ref("请选择班级");
let classId = ref("");
let classList = ref([
  { id: 1, className: "1班" },
  { id: 2, className: "2班" },
  { id: 3, className: "3班" },
  { id: 4, className: "4班" },
  { id: 5, className: "5班" },
  { id: 6, className: "6班" },
]);
const clickClass = (id, name) => {
  classId.value = id;
  className.value = name;
  showClass.value = false;
};
</script>

<style lang="less" scoped>
@import "@/common/style/adapting.less";
@import "@/common/style/pageContent.less";

:deep(.van-empty__description) {
  font-size: 10px;
}

:deep(.van-button--block) {
  width: 40%;
  height: 30px;
  font-size: 12px;
  margin: auto;
}

.addForm {
  width: 50vw;
  padding: 10px;
  box-sizing: border-box;
  font-size: 12px;
  h2 {
    text-align: center;
    margin: 0;
    font-weight: 600;
    font-size: 12px;
  }
  .gradeAndClass {
    display: flex;
    color: #777;
    display: flex;
    .grade,
    .class {
      margin-right: 50px;
      position: relative;
      .gradeSelect,
      .classSelect {
        width: 60px;
        position: absolute;
        z-index: 9999999;
        top: 20px;
        border-radius: 5px;
        line-height: 20px;
        padding: 5px;
        background-color: #eee;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 40%;
          width: 0;
          height: 0;
          transform: translateY(-90%);
          border-style: solid;
          border-width: 0 5px 5px 5px;
          border-color: transparent transparent#eee transparent;
        }
      }
      .gradeSelect ul,
      .classSelect ul {
        height: 100px;
        overflow-y: scroll;
      }
    }
  }
}

.fnBtn {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  .van-button {
    width: 40%;
  }
}

:deep(.van-popup--center) {
  border-radius: 10px;
}
@media screen and (orientation: portrait) and (max-device-width: 420px) {
  .addForm {
    width: 90vw;
  }
  .classManagement {
    background-color: #fff;
  }
}
</style>
