<template>
  <ul class="classListBox">
    <li v-for="(item, index) in list">
      <div>
        <van-image
          width="60px"
          height="60px"
          :src="require('@/assets/images/classListIcon.png')"
        />
        <div class="classInfo">
          <div>
            {{ item.gradeName }}{{ item.className }}{{ item.studentTotal }}人
          </div>
          <div style="color: rgb(112, 181, 3)">
            我是本班的{{
              item.subjectList.length ? item.subjectList[0].subjectName : ""
            }}老师
          </div>
        </div>
      </div>
      <div
        style="color: #18a4e0; cursor: pointer"
        @click="gotoStudentList(item.classID, item.gradeID, index)"
      >
        学生列表>
      </div>
    </li>
  </ul>
</template>

<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
defineProps(["list"]);

const gotoStudentList = (classId, gradeId, index) => {
  router.push({
    path: "/teacher/studentList",
    query: { class_id: classId, grade_id: gradeId, current: index },
  });
};
</script>

<style lang="less" scoped>
.classListBox {
  li {
    font-size: 12px;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      .classInfo {
        margin-left: 20px;
      }
    }
  }
}
</style>
